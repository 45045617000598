export const CHAT_USER = 'CHAT_USER';
export const ACTIVE_USER = 'ACTIVE_USER';
export const FULL_USER = 'FULL_USER';
export const ADD_LOGGED_USER = 'ADD_LOGGED_USER';
export const CREATE_GROUP = 'CREATE_GROUP';
export const ONLINE_USERS = 'ONLINE_USERS';
export const LAST_MESSAGES = 'LAST_MESSAGES';
export const ACTIVE_CHAT = 'ACTIVE_CHAT';

export const CHAT_LIST = 'CHAT_LİST';
export const ADD_CHAT_LIST = 'ADD_CHAT_LİST';
export const UPDATE_CHAT_LIST = 'UPDATE_CHAT_LIST';

export const ACTIVE_CHAT_ID = 'ACTIVE_CHAT_ID';
export const DELETE_CHAT = 'DELETE_CHAT';

export const CHANGE_LAST_MESAGE = 'CHANGE_LAST_MESAGE';
export const UPDATE_SINGLE_MESSAGE = 'UPDATE_SIGNLE_MESSAGE';

export const CONNECTION_DATA = 'CONNECTION_DATA';

export const OLD_MESSAGE_LOADİNG = 'OLD_MESSAGE_LOADİNG';
export const OLD_MESSAGE_LOADİNG_SUCCESS = 'OLD_MESSAGE_LOADİNG_SUCCESS';
export const OLD_MESSAGE_LOADİNG_ERROR = 'OLD_MESSAGE_LOADİNG_ERROR';

export const NEW_CHAT_START = 'NEW_CHAT_START';

export const NEW_MESSAGE = 'NEW_MESSAGE';
export const NEW_MESSAGE_NEW_CHAT = 'NEW_MESSAGE_NEW_CHAT';

export const UPDATE_NEWCHATS = 'UPDATE_NEWCHATS';

export const OPEN_WITH_CHATROOM = 'OPEN_WITH_CHATROOM';
export const OPEN_WITH_DM = 'OPEN_WITH_DM';

export const UPDATE_PAGE = 'UPDATE_PAGE';

export const LAST_MESSAGE_ALERT = 'LAST_MESSAGE_ALERT';

export const NEW_DM_USER = 'NEW_DM_USER';
